import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../layouts/en'
import Title from '../components/Titles/Title'
import SubSubTitle from '../components/Titles/SubSubTitle'

const lang = 'en'

const JobTemplate = ({ data, location }) => {
  const { job } = data
  const translations = job.translations.find((t) => t.language === lang)

  return (
    <Layout location={location}>
      <Helmet title={`${translations.title}`} />
      <div className="relative">
        <Title>{translations.title}</Title>
        <div className="mx-10 sm:mx-20">
          <div className="flex mb-4 font-text">
            <div className="flex flex-wrap text-sm font-medium text-black uppercase font-text">
              {job.time_scope && (
                <div className="inline-flex px-3 py-1 mb-2 mr-2 bg-yellow-500 rounded-sm">
                  <StaticImage
                    src="../assets/img/clock.svg"
                    className="h-[20px] mr-1"
                    alt=""
                    height={20}
                  />
                  <span className="m-0">{job.time_scope} Stunden/Woche</span>
                </div>
              )}
              {job.location && (
                <div className="inline-flex px-3 py-1 mb-2 mr-2 bg-yellow-500 rounded-sm">
                  <StaticImage
                    src="../assets/img/location.svg"
                    className="h-[20px] mr-1"
                    alt=""
                    height={20}
                  />
                  <span className="m-0">{job.location}</span>
                </div>
              )}
              {job.limitation && (
                <div className="inline-flex px-3 py-1 mb-2 mr-2 bg-yellow-500 rounded-sm">
                  <StaticImage
                    src="../assets/img/handshake.svg"
                    className="h-[20px] mr-[5px]"
                    alt=""
                    height={20}
                  />
                  <span className="m-0">
                    {job.limitation ? 'Befristet' : 'Unbefristet'}
                  </span>
                </div>
              )}
              {translations.start_date && (
                <div className="inline-flex px-3 py-1 mb-2 mr-2 bg-yellow-500 rounded-sm">
                  <span className="m-0">Start: {translations.start_date}</span>
                </div>
              )}
            </div>
          </div>
          <SubSubTitle>Description</SubSubTitle>
          <p
            className="my-6"
            dangerouslySetInnerHTML={{ __html: translations.description }}
          ></p>
          <SubSubTitle>Resposibilities</SubSubTitle>
          <p
            className="my-6"
            dangerouslySetInnerHTML={{ __html: translations.responsibilities }}
          ></p>
          <SubSubTitle>Qualifications</SubSubTitle>
          <p
            className="my-6"
            dangerouslySetInnerHTML={{ __html: translations.qualifications }}
          ></p>
          <SubSubTitle>Benefits</SubSubTitle>
          <p
            className="my-6"
            dangerouslySetInnerHTML={{ __html: translations.benefits }}
          ></p>
          <SubSubTitle>Salary</SubSubTitle>
          <p
            className="my-6"
            dangerouslySetInnerHTML={{ __html: translations.salary }}
          ></p>
          <a
            href={job.link}
            className="inline-flex px-6 py-3 my-4 bg-yellow-500 rounded-sm shadow-lg shadow-black-500/50 hover:bg-yellow-500"
          >
            <StaticImage
              src="../assets/img/link.svg"
              className="h-[25px] mr-2"
              alt=""
              height={20}
            />
            <span className="font-medium text-black uppercase font-text">
              Click here to apply
            </span>
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default JobTemplate

export const pageQuery = graphql`
  query ($id: String!) {
    job: directusJob(id: { eq: $id }) {
      id
      slug
      limitation
      location
      time_scope
      link
      translations {
        language
        qualifications
        responsibilities
        salary
        title
        description
        benefits
        start_date
      }
    }
  }
`
